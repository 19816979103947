import useUserStore from '~/store/user';
import API from '~/services/api';
import create from 'zustand';
import { persist } from 'zustand/middleware';
import { Team, Allocation, BasicUser } from '~/models/types';

type TeamState = {
  team: Team;
  allocations: Allocation[];
  teamUsers: BasicUser[];
  fetchTeamData: () => void;
  hasIncompleteSubscription: () => boolean;
  fetchAllocationsData: () => Promise<void>;
  fetchTeamUsersData: () => Promise<void>;
};

const { getState } = useUserStore;

const useTeamStore = create<TeamState>(
  persist(
    (set, get) => ({
      team: {} as Team,
      allocations: [],
      teamUsers: [],
      fetchTeamData: async () => {
        const response = await API.team.get_teams();
        const data = await response.json();
        set({ team: data[0] });
      },
      hasIncompleteSubscription: () => {
        return (
          getState().isLoggedUserAdmin() &&
          get().team.subscription &&
          !get().team.subscription.inPaidPeriod &&
          !get().team.subscription.validForever
        );
      },
      fetchAllocationsData: async () => {
        const loggedUser = await API.auth.getLoggedUser();
        if (!loggedUser) return;
        const response = await API.user.getAllocations(
          loggedUser.allocations[0].team.id,
        );
        const data = await response.json();
        set({ allocations: data });
      },
      fetchTeamUsersData: async () => {
        const response = await API.team.getTeamUsers(getState().user.team.id);
        const data = await response.json();
        set({ teamUsers: data });
      }
    }),
    {
      name: 'useTeamStore',
    },
  ),
);

export default useTeamStore;
